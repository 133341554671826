import { Stack } from '@uala/abra';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 40%;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftContainer = styled.div`
  background-image: url('/images/illustrations/home-desktop.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 780px) {
    background-image: url('/images/illustrations/home-mobile.png');
  }
`;

export const CustomStack = styled(Stack)`
  flex-direction: column;
  padding-bottom: 40px;
  max-width: 80%;
  margin: 0 auto;

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;

    & > button {
      min-width: auto;
    }
  }
`;
