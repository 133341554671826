import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import dataPPFF from './data/dataRequestFormPPFF.json';
import dataPPMM from './data/dataRequestFormPPMM.json';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import { Row, RowList, Stack } from '@uala/abra';
import { PERSON_TYPE } from '../../shared/constants';
import { CodeNotification } from './styles';

const DataRequest: React.FC = () => {
  const navigate = useNavigate();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;
  const currentData = personType === PERSON_TYPE.PPMM ? dataPPMM : dataPPFF;
  const [showNotification, setShowNotification] = useState(true);

  const onSubmit = async () => {
    createAmplitudeEvent('registro_cobros_selecciona_completar_datos', {
      device,
      user_type: personType
    });
    navigate('/onboarding');
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <CodeNotification className={showNotification ? '' : 'hide'}>
        ¡Ya validamos tu email!
      </CodeNotification>

      <GenericForm
        form={currentData}
        onSubmit={onSubmit}
        loading={false}
        showLogo={isDesktop}
        illustrationPosition={isDesktop ? 'side' : undefined}
        form_variant="information-page"
      >
        {personType === PERSON_TYPE.PPMM && (
          <>
            <p style={{ margin: 12, textAlign: 'center' }}>Ten a mano:</p>
            <Stack align="center" css={{ margin: '0 auto' }}>
              <RowList hasDivider={false} css={{ marginBottom: '24px' }}>
                <Row
                  css={{
                    padding: '$4',
                    height: '$24',
                    svg: { color: '$information-middle' },
                    span: { fontWeight: 400 }
                  }}
                  title="RFC y Fecha de constitución"
                  icon="check"
                ></Row>
                <Row
                  css={{
                    padding: '$4',
                    height: '$24',
                    svg: { color: '$information-middle' },
                    span: { fontWeight: 400 }
                  }}
                  title="CLABE interbancaria"
                  icon="check"
                ></Row>
                <Row
                  css={{
                    padding: '$4',
                    height: '$24',
                    svg: { color: '$information-middle' },
                    span: { fontWeight: 400 }
                  }}
                  title="Datos de la persona apoderada"
                  icon="check"
                ></Row>
              </RowList>
            </Stack>
          </>
        )}
      </GenericForm>
    </>
  );
};

export default DataRequest;
