import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import FormLayout from '../../components/GenericForm/FormLayout';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { Button, Illustration, Spacer, Stack, Text, Title } from '@uala/abra';
import { sendEmailValidation } from '../../services/auth';
import { UserDataContext } from '../../contexts/UserDataProvider';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import { useSnackbar } from '@uala/ui';
import { PERSON_TYPE } from '../../shared/constants';

export const ValidateEmail: React.FC = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const addSnackbar = useSnackbar();
  const user = useContext(UserDataContext);
  const device = useGetDevice();
  const navigate = useNavigate();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;

  const handleEmail = () => {
    createAmplitudeEvent('registro_cobros_envia_codigo_mail', {
      device,
      country: 'mex'
    });

    if (user && user.accountEmail) {
      sendEmailValidation(user.accountEmail)
        .then(() => {
          navigate('/email-code');
        })
        .catch(() => {
          createAmplitudeEvent('registro_cobros_error_api', {
            device,
            tipo_error: 'enviar_token',
            user_type: personType
          });
          addSnackbar('Error al enviar mail');
        });
    }
  };

  return (
    <FormLayout
      showLogo={isDesktop}
      illustration={isDesktop ? 'time' : undefined}
    >
      <Stack
        direction="column"
        justify="space-between"
        css={{ height: '100%' }}
      >
        <Stack direction="column" align="center" css={{ textAlign: 'center' }}>
          <Illustration name="large-tienda-uala-bis" />

          <Title>Valida tu email {user?.accountEmail}</Title>

          <Text>
            Vamos a enviarte un código a tu email para poder validarlo.
          </Text>
        </Stack>

        <Spacer y="40" />

        <Stack direction="column">
          <Button onClick={handleEmail}>Enviar código</Button>

          <Button onClick={() => navigate('/create-account')} variant="text">
            Cambiar email
          </Button>
        </Stack>
      </Stack>
    </FormLayout>
  );
};
