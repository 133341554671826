import { useNavigate } from 'react-router-dom';
import { Stack, Text, Title, Button } from '@uala/abra';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { LeftContainer, Container, CustomStack } from './styles';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';

export const Home: React.FC = () => {
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const navigate = useNavigate();

  return (
    <Container>
      <LeftContainer>
        <CustomStack>
          <Stack direction="column" css={{ paddingBottom: '40px' }}>
            <Title size="lg" color="white">
              Incrementa tus ventas con Ualá Bis
            </Title>
            <Text color="white">
              ¡Recibe el dinero al instante! Tenemos las comisiones más bajas
              del mercado.
            </Text>
          </Stack>

          <Button
            onClick={() => {
              createAmplitudeEvent(
                'registro_cobros_selecciona_comenzar_registro',
                {
                  device
                }
              );
              navigate('/person-type');
            }}
            css={{
              backgroundColor: '#fff',
              color: '#022A9A',
              '&:hover': {
                background: '#fff'
              }
            }}
          >
            Comenzar
          </Button>
        </CustomStack>
      </LeftContainer>

      {isDesktop && (
        <Stack
          align="center"
          justify="center"
          css={{ backgroundColor: '#F0F4FF' }}
        >
          <img
            src={`images/illustrations/create-account.svg`}
            alt="ilustración"
          />
        </Stack>
      )}
    </Container>
  );
};
