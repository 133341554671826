import styled, { keyframes } from 'styled-components';

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const CodeNotification = styled.div`
  width: 100%;
  background-color: #196b4d;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  animation: ${slideIn} 0.5s ease-out forwards;

  &.hide {
    animation: ${slideOut} 0.5s ease-in forwards;
  }
`;
