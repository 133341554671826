import styled from 'styled-components';
import { PinInput } from '@uala/ui';
import { ResendButtonProps } from './types';
import {
  Container,
  Subtitle,
  Title
} from '../../components/GenericForm/styles';

export const VerificationFormContainer = styled(Container)`
  flex-direction: column;
  flex-wrap: nowrap;
  height: unset;
  margin: 25px 0px;
  @media (max-width: 1200px) {
    margin-top: 0px;
  }
`;

export const VerificationContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledPinInput = styled(PinInput)`
  & input {
    max-width: 50px;
    max-height: 50px;
    width: 50px;
    height: 50px;
    background-color: #ededed;
    border: none;

    :last-of-type {
      margin-right: 0px;
    }

    @media (max-width: 1200px) {
      width: 48px;
      height: 48px;
      margin-right: 7px;
    }
    @media (max-width: 480px) {
      width: 46px;
      height: 46px;
      margin-right: 5px;
    }
    @media (max-width: 375px) {
      width: 40px;
      height: 40px;
      margin-right: 3px;
    }
  }

  &.expired > input {
    color: #e74442;
    border-color: #e74442;
    background-color: #fafafa;
    border: 1px solid #e74442;
  }
`;

export const Error = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #e74442;
  text-align: center;
  margin: 0px auto 10px;
`;

export const Timer = styled.span<{ error: Boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: ${(props) => (props.error ? '#E74442' : '#3564fd')};
  text-align: center;
  margin: 0px auto 25px;
`;

export const ResendButton = styled.button<ResendButtonProps>`
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #3564fd;
  text-align: center;
  justify-content: flex-end;
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0px auto 30px;
`;

export const VerificationTitle = styled(Title)``;

export const VerificationSubtitle = styled(Subtitle)`
  margin: 0px 0px 30px 0px;
  align-self: center;
`;

export const WrongEmail = styled.span`
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #3a3a3a;

  @media (max-width: 1200px) {
    margin-top: auto;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #3564fd;
    text-decoration: none;
  }
`;
