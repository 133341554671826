import React, { useState } from 'react';
import { Input } from '@uala/ui';
import { useFormContext } from 'react-hook-form';
import { ControlElement } from '../../types';

export const CustomInput: React.FC<ControlElement> = (
  { name, placeholder, type, description = '', validations, readOnly = false },
  props: any
) => {
  const {
    register,
    formState: { errors, touchedFields }
  } = useFormContext();
  const [isInFocus, setIsInFocus] = useState(false);
  const inputField = register(name, validations);
  const formValues = JSON.parse(
    window.sessionStorage.getItem('formValues') || '{}'
  );

  const [showPassword, setShowPassword] = useState(false);

  const onWheel = (e: any) => {
    e.currentTarget.blur();
  };

  const blockInvalidInputs = (e: any) => {
    if (
      type === 'number' &&
      ['e', 'E', '+', '-', '.', 'ArrowUp', 'ArrowDown'].includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const handleInputMode = (fieldType: string) => {
    switch (fieldType) {
      case 'number':
        return 'numeric';
      case 'email':
        return 'email';
      default:
        return 'text';
    }
  };

  const showPasswordButton = () => (
    <button
      type="button"
      onClick={(e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
      }}
    >
      {showPassword ? 'Ocultar' : 'Mostrar'}
    </button>
  );

  const finalType = (() => {
    if (type === 'password' && showPassword) {
      return 'text';
    }
    return type;
  })();

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    inputField.onBlur(e);
    setIsInFocus(false);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsInFocus(true);
  };

  const error = errors[name] && touchedFields[name];

  return (
    <Input
      {...inputField}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      id={name}
      label={placeholder}
      type={finalType}
      onWheel={onWheel}
      {...props}
      error={error && !isInFocus ? true : false}
      helperText={
        error && !isInFocus ? errors[name]!.message : !isInFocus || description
      }
      onKeyDown={blockInvalidInputs}
      inputMode={handleInputMode(type)}
      rightAddon={type === 'password' ? showPasswordButton() : null}
      readOnly={readOnly}
      defaultValue={formValues[name] || null}
    ></Input>
  );
};
